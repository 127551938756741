import { store } from "../redux/app/store";
import axios from "axios";
// import { setError } from "../redux/features/error/errorSlice";

  export const fansupportPublic = axios.create({
    baseURL: "https://apitest.fansupport.com",
  });
  export const  fansupportPrivate = axios.create({
    baseURL: "https://apitest.fansupport.com",    
  }); 

  fansupportPublic.interceptors.request.use(
    async (config) => {
      const language = localStorage.getItem("selectedLanguageCode") ? localStorage.getItem("selectedLanguageCode") : navigator.language;
      config.headers["Accept-Language"] = language;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )

  fansupportPublic.interceptors.request.use(
    async (config) => {
      const currency = localStorage.getItem("selectedCurrencyCode") ? localStorage.getItem("selectedCurrencyCode") : 978;
      config.headers["x-currency"] = currency;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )
  fansupportPrivate.interceptors.request.use(
    async (config) => {
      const currency = localStorage.getItem("selectedCurrencyCode") ? localStorage.getItem("selectedCurrencyCode") : 978;
      config.headers["x-currency"] = currency;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )
  fansupportPrivate.interceptors.request.use(
    async (config) => {
      const language = localStorage.getItem("selectedLanguageCode") ? localStorage.getItem("selectedLanguageCode") : navigator.language;
      config.headers["Accept-Language"] = language;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )

  // fansupportPublic.interceptors.response.use(
  //   (response) => {
  //     const responseBody = response.data;

  //     // "IsSuccess" alanı false ise işleme başla
  //     if (responseBody && responseBody.IsSuccess === false) {
  //       console.error('İstek başarısız oldu:', responseBody);
  //       return [];
  //     }
  
  //     // Başarılı yanıtları doğrudan geçir
  //     return response;
  //   }
  // );

  // fansupportPublic.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     console.error("Global Error", error);
  //     console.error("Global Error", error.response.data.title);

  //     const errorMessage = error.response.data.title || 'An unexpected error occurred';
  //     store.dispatch(setError(errorMessage));
  //   }
  // );

  fansupportPrivate.interceptors.request.use(
    async (config) => {
      const user = store?.getState()?.user?.user;
      const token = user ? user?.data.accessTokenDto : null;
      const currentDate = new Date();
  
      if (token?.accessToken) {
        const expDate = new Date(token.expireDate);
        if (currentDate < expDate) {
          config.headers["Authorization"] = `Bearer ${token.accessToken}`;
          config.headers["x-currency"] = store?.getState()?.filter?.currency;
        } else {
          const user = store?.getState()?.user?.user;
          const refreshToken = user?.data.accessTokenDto?.refreshToken;
          try {
            const response = await fansupportPublic.post('/identity/new-token', {
              value: refreshToken
            });
            const newAccessToken = response?.data?.data?.accessToken;
            config.headers["Authorization"] = `Bearer ${newAccessToken}`;
            config.headers["x-currency"] = store?.getState()?.filter?.currency;
          } catch (error) {
            console.error("Token repurchase error:", error);
          }
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );